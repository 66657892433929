import { Injectable, TemplateRef } from '@angular/core';
import {
  ApiMethod,
  authType,
  Endpoints,
  ORDERSERVICE,
} from '../utils/constants';
import { DatePipe } from '@angular/common';
import { HttpService } from '../http/http.service';
import { LodashService } from '../lodash/lodash.service';
import { Observable } from 'rxjs';
import { FormSection } from 'src/app/shared/components/dynamic-form/form-sections.model';
import {
  SCP_API_CONFIG_SECTION,
  SFTP_CONFIG_SECTION,
} from 'src/app/components/scp/form-config/form-config.model';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  scpInfo: any = {}; // LIst API details
  onboardingInfo: any = {};

  constructor(
    private _http: HttpService,
    private datePipe: DatePipe,
    private lodash: LodashService
  ) {}

  getOrderServiceInfo() {
    let endpoint: any = Endpoints.ORDER_SERVICE_INFO;
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }

  downloadCode(data: any) {
    let endpoint: any = `${Endpoints.GET_STAMP_CODE_DOWNLOAD}?order_code=${data?.code}`;
    return this._http.requestCall(endpoint, ApiMethod.DOWNLOAD_FILE);
  }

  includeOthersReqConfig(
    otherInfoConfig: FormSection[],
    orderService: any,
    cb: any, //call back function,
    template: TemplateRef<any>
  ) {
    const index = otherInfoConfig?.findIndex(
      (x) => x.key == ORDERSERVICE.ftp || x.key == ORDERSERVICE.api
    );
    if (index !== -1) otherInfoConfig.splice(index, 1);
    if (orderService?.scp_delivery_type == ORDERSERVICE.ftp) {
      otherInfoConfig?.push(SFTP_CONFIG_SECTION);
    } else if (orderService?.scp_delivery_type == ORDERSERVICE.api) {
      this.getDeliveryApi().subscribe((response: any) => {
        SCP_API_CONFIG_SECTION.fields[0].templateRef = template;
        otherInfoConfig?.push(SCP_API_CONFIG_SECTION);
        cb(response);
      });
    }
  }

  private toggleSCPFields(data: any, form?: any, isEdit?: boolean): void {
    const controls: any = [
      {
        form: form.get('api_auth_url'),
        enable: data.name == authType?.TOKEN,
      },
      {
        form: form.get('username'),
        enable: data.name == authType?.BASIC,
      },
      {
        form: form.get('password'),
        enable: data.name == authType?.BASIC,
      },
    ];

    // Update control states and labels
    controls.forEach((control: any) => {
      if (control.form && control.enable) control.form.enable();
      else control.form.disable();
    });
  }

  getDeliveryApi() {
    let endpoint: any = Endpoints.GET_DELIVERY_API;
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }
  getFtpType() {
    let endpoint: any = Endpoints.GET_FTP_TYPE;
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }
  getApiType() {
    let endpoint: any = Endpoints.GET_API_TYPE;
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }
  getAuthType() {
    let endpoint: any = Endpoints.GET_AUTH_TYPE;
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }

  getAvailableCodeCount(id: any) {
    let endpoint: any = `${Endpoints.GET_AVAILABLE_COUNT}?manufacturer_id=${id}`;
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }

  /**
   * @description
   * Get obboard details .......................
   * @param id current registration id
   * @returns Return respective API response
   */
  getOnboard(id: any) {
    let endpoint: any = Endpoints.ONBOARD_SCP_USER + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }

  //.......................Get obboard details

  /**
   * @description
   * Get obboard details .......................
   * @param id current registration id
   * @returns Return respective API response
   */
  getSCP(id: any) {
    let endpoint: any = Endpoints.GET_SCP_LIST + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }

  //.......................Get obboard details

  /**
   * @description
   * Get obboard details .......................
   * @param id current registration id
   * @returns Return respective API response
   */
  getSCPRegistrationHeaderInfo(id: any) {
    let endpoint: any = Endpoints.GET_SCB_REGN_CARD_STATUS + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }

  //.......................Get obboard details

  /**
   * @description
   * Save onboard details ............................
   * @param id current registration id
   * @param formData Payload details
   * @returns Return respective API response
   */

  saveDetails(id: any, formData: any) {
    let endpoint: any;
    endpoint = `${Endpoints.ONBOARD_PROCESS}${id}/`;
    return this._http.requestCall(endpoint, ApiMethod.POST, formData, {
      observe: 'response',
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  //............................Save onboard details

  /**
   * @description
   * Update onboard details ............................
   * @param id current registration id
   * @param formData Payload details
   * @returns Return respective API response
   */

  updateDetails(id: any, formData: any) {
    let endpoint: any;
    endpoint = `${Endpoints.ONBOARD_PROCESS}${id}/`;
    return this._http.requestCall(endpoint, ApiMethod.PUT, formData, {
      observe: 'response',
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  //............................Update onboard details

  /**
   * @description
   * Get Next status for SCP onboard .................
   * @param id current registration id
   * @returns Return respective API response
   */

  getNextStatus(id: any): Observable<any> {
    let endpoint: any = Endpoints.ONBOARD_PROCESS_NEXT_STATUS + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }

  //.................Get Next status for SCP onboard

  /**
   * Status change API .................................
   * @param formData
   * @param currentId current registration id
   * @param nextActionName Next action
   * @returns Return respective API response
   */

  finalSave(formData: any, currentId: any, nextActionName: string = '') {
    let endpoint: any =
      Endpoints.ONBOARD_PROCESS_STATUS_CHANGE +
      currentId +
      `/?status_key=${nextActionName}`;
    return this._http.requestCall(endpoint, ApiMethod.PUT, formData, {
      observe: 'response',
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  //.................................Status change API

  getVersionHistory(id: any) {
    let endpoint: any = Endpoints.VERSION_HISTORY + id + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET);
  }
}
